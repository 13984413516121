import { FC } from 'react';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { formatPrice } from '@/shared/lib/utils';
import styles from './basket-beautiful-number.module.scss';

interface BasketBeautifulNumberProps {
  number: string;
  price?: number;
  timer?: string;
  isMainPage?: boolean;
}

export const BasketBeautifulNumber: FC<BasketBeautifulNumberProps> = ({ number, price, timer, isMainPage }) => {
  return isMainPage ? (
    <div className={styles.main}>
      <span className={styles.main__title}>Красивый номер {timer ? timer : ''}</span>
      <span className={styles.main__number}>{formatPhoneNumber(number, false)}</span>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.block}>
          <span className={styles.title}>Красивый номер</span>
          <div className={styles.info}>
            <span className={styles.number}>{formatPhoneNumber(number, false)}</span>
            {timer && <span>{timer}</span>}
          </div>
        </div>
        <div className={styles.title}>{formatPrice(price)}</div>
      </div>
    </div>
  );
};
