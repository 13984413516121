import { Input, PageMeta } from '@/shared/ui-kit';
import { useGetSalesPointsQuery } from '@/store/api/sales-points/sales-points.api';
import { Map } from '@/shared/ui-kit/map';
import { FC, useLayoutEffect, useMemo, useState } from 'react';
import { salesPointMapper } from '@/shared/lib/utils';
import { MapPlacemarkInfo } from '@/shared/ui-kit/map-placemark-info';
import { useDisclosure } from '@mantine/hooks';
import { Point } from '@/shared/ui-kit/map/map.types';
import { SalesPointEntity } from '@/store/api/sales-points/sales-points.type';
import { MapEvent } from 'yandex-maps';
import cn from 'classnames';
import styles from './sales-point-map.module.scss';

interface SalesPointsMapProps {
  isForm?: boolean;
  isError?: boolean;
  onSelect?: (point: Point) => void;
  withoutSearch?: boolean;
  onMapReady?: () => void;
  searchPlaceholder?: string;
  dataFromServer?: SalesPointEntity | undefined;
}

export const SalesPointsMap: FC<SalesPointsMapProps> = ({
  dataFromServer,
  isForm,
  isError,
  onSelect,
  withoutSearch,
  onMapReady,
  searchPlaceholder,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [opened, { close, open }] = useDisclosure(false);
  const [selectedPoint, setSelectedPoint] = useState<Point>();
  const { data: dataSalesPoint } = useGetSalesPointsQuery({ populate: '*', 'pagination[limit]': 100 });
  const [isLoaded, setIsLoaded] = useState(false);
  const currentData = dataFromServer || dataSalesPoint;

  const handlePlacementClick = (e: MapEvent) => {
    const point = e.get('target').properties._data;
    setSelectedPoint(point);
    open();
  };

  useLayoutEffect(() => {
    setIsLoaded(true);
  }, []);

  const handlePlacementSelect = () => {
    selectedPoint && onSelect?.(selectedPoint);
    close();
  };

  const points = useMemo(() => {
    return salesPointMapper(currentData).filter((item) =>
      item.fullAddress.toLowerCase().includes(searchValue.toLowerCase().trim()),
    );
  }, [currentData, searchValue]);

  return (
    <div className={styles.container}>
      {!withoutSearch && <Input onChange={setSearchValue} placeholder={searchPlaceholder || 'Поиск отделения'} />}
      <Map
        points={points}
        handlePlacementClick={handlePlacementClick}
        activePlacemarkId={selectedPoint?.id}
        onMapReady={onMapReady}
        className={cn(styles.map, { [styles.map__form]: isForm, [styles.map__error]: isError })}
        infoObject={
          opened ? (
            <>
              <PageMeta {...selectedPoint?.meta_data} />
              <MapPlacemarkInfo
                title={selectedPoint?.settlement}
                street={selectedPoint?.address}
                services={selectedPoint?.services}
                time={selectedPoint?.time}
                inForm={isForm}
                showInfo={opened}
                onSelect={handlePlacementSelect}
              />
            </>

          ) : null
        }
      />
      {!isLoaded && (
        <ul className={styles.list}>
          {points?.map((el) => <li key={el.id}>{el.address}</li>)}
        </ul>
      )}
    </div>
  );
};
