import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { HOME_INTERNET_SERVICE_TYPE } from '@/store/api/service-home-internet/service-home-internet.type';
import { changeMobileServices } from '@/store/slice/mobile-tariff.slice';
import { changeHomeInternetServices } from '@/store/slice/home-internet.slice';
import { TariffServiceProps } from '@/shared/ui-kit/tariff-service';
import { Features } from '@/shared/ui-kit/tariff-card/features';
import { TariffCardOptionProps } from '@/shared/ui-kit/tariff-card/tariff-card-option/tariff-card-option.types';
import { CHECK_INFORMATION, DETSKIJ_NAME } from '@/modules/tariff/mobile-tariffs/constants';
import { promotionDescriptionMaker } from '@/modules/tariff/shared/helpers';
import { TITLES } from '../../constants';
import { BasketOptions } from './basket-options';
import { BasketServices } from './basket-services/indes';
import { BasketFooter } from './basket-footer';
import { BasketBeautifulNumber } from './basket-beautiful-number';
import styles from './basket-main-content.module.scss';

interface MainContentProps {
  confirmation?: boolean;
  isMainPage?: boolean;
  isHomeInternetPage?: boolean;
  totalBasketPrice: number;
  totalBasketPriceWithPromotion?: number;
}

const MainContent: FC<MainContentProps> = ({
  confirmation,
  isMainPage,
  isHomeInternetPage,
  totalBasketPrice,
  totalBasketPriceWithPromotion,
}) => {
  const {
    status: mobileTariffStatus,
    mobileTariff,
    mobileTouchedServices,
  } = useAppSelector((store) => store.mobileTariff);
  const {
    status: homeInternetStatus,
    optionsHomeInternet,
    homeInternetTouchedServices,
    speed,
    buildingType,
  } = useAppSelector((store) => store.homeInternet);
  const dispatch = useAppDispatch();
  const isConvergent =
    mobileTariffStatus.added &&
    homeInternetStatus.added &&
    mobileTariff?.optionsMobile?.length > 0 &&
    optionsHomeInternet.length > 0;

  const { selectedNumber } = useAppSelector((state) => state.beautifulNumber);

  const homeInternetServices: TariffServiceProps[] = !homeInternetStatus.added
    ? []
    : homeInternetTouchedServices
      ?.filter(({ service_type }) => service_type !== HOME_INTERNET_SERVICE_TYPE.SALE)
      ?.map(({ localized_name, promotion_description, prices, added, description, shpd_id }) => ({
        title: localized_name,
        servicePrice: promotion_description
          ? promotion_description
          : isConvergent
            ? prices?.convergent_price || 0
            : prices?.mono_price || 0,
        added,
        description,
        onChange: () =>
          dispatch(
            changeHomeInternetServices(
              homeInternetTouchedServices.map((service) =>
                service.shpd_id === shpd_id ? { ...service, added: !added } : service,
              ),
            ),
          ),
      }));
  const mobileServices: TariffServiceProps[] = !mobileTariffStatus.added
    ? []
    : mobileTouchedServices?.map((item) => ({
      ...item,
      onChange: () =>
        dispatch(
          changeMobileServices(
            mobileTouchedServices.map((service) =>
              service.serv_id === item.serv_id ? { ...service, added: !item.added } : service,
            ),
          ),
        ),
    }));

  const mainPageMobileOptions: TariffCardOptionProps[] | undefined = isMainPage
    ? [
      { icon: 'phoneTariff', value: mobileTariff.localized_name, text: 'тариф' },
      ...mobileTariff.optionsMobile.map(({ from, ...rest }) => rest),
    ]
    : undefined;

  const services: TariffServiceProps[] = isHomeInternetPage
    ? [...homeInternetServices, ...mobileServices]
    : [...mobileServices, ...homeInternetServices];

  return (
    <div className={styles.wrapper}>
      {confirmation && <p className={styles.warning}>{CHECK_INFORMATION}</p>}
      <div className={styles.main__container}>
        {isMainPage && selectedNumber && (
          <BasketBeautifulNumber number={selectedNumber.msisdn} isMainPage={isMainPage} />
        )}
        {isHomeInternetPage ? (
          <BasketOptions title={isConvergent ? TITLES.HOME_INTERNET : undefined} options={optionsHomeInternet} />
        ) : (
          <BasketOptions
            title={isConvergent ? TITLES.MOBILE : undefined}
            options={mainPageMobileOptions || mobileTariff?.optionsMobile}
          />
        )}
        {isConvergent &&
          (isHomeInternetPage ? (
            <BasketOptions title={TITLES.MOBILE} options={mobileTariff?.optionsMobile} />
          ) : (
            <BasketOptions title={TITLES.HOME_INTERNET} options={optionsHomeInternet} />
          ))}
        {mobileTariff.name === DETSKIJ_NAME && <Features data={mobileTariff.features} isPurple isBasket />}
        {services.length > 0 && <BasketServices services={services} />}
        {!isMainPage && selectedNumber && (
          <BasketBeautifulNumber number={selectedNumber.msisdn} price={selectedNumber.price} />
        )}
        <BasketFooter
          homeInternetAdded={homeInternetStatus.added}
          price={totalBasketPrice}
          priceWithPromotion={totalBasketPriceWithPromotion}
          isMainPage={isMainPage}
          promotionPeriod={speed.promotion_period}
          promotionDescription={promotionDescriptionMaker({
            price: totalBasketPrice,
            promotionPrice: totalBasketPriceWithPromotion,
            promotionPeriod: speed.promotion_period,
            text: speed.promotion_description,
            buildingType,
          })}
        />
      </div>
    </div>
  );
};

MainContent.displayName = 'MainContent';
export { MainContent };
