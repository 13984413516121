import { FC, ReactNode, memo, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { Box, Button, Accordion, Modal, ScrollArea, LoadingOverlay, Checkbox } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import Cookies from 'js-cookie';
import { addHours, format, isAfter } from 'date-fns';
import cn from 'classnames';
import { useSendSmsMutation } from '@/store/api/sms-auth/sms-auth.api';
import { useGetAboutCompanyQuery } from '@/store/api/about-company/about-company.api';
import { useAppSelector } from '@/store/hooks';
import { PhoneInput, RichText, Tooltip } from '@/shared/ui-kit';
import {
  AccordionItem,
  EmailInput,
  RadioGroup,
  TextInput,
  DateTimePicker,
  Control,
  SmsPanel,
} from '@/shared/ui-kit/form';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';
import { SELECTED_ICON, SelectedItem } from '@/shared/ui-kit/form/selected-item';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { storage } from '@/shared/lib/storage';
import { decodeInviteCode, formatPrice } from '@/shared/lib/utils';
import { toggleWidgetVisibility } from '@/shared/lib/chat2desk-utils';
import { apiEndpoints, LINKS } from '@/shared/constants/links';
import { ArrowDownIcon, CloseIcon, ShoppingCart, TimeIcon } from '@/shared/assets/svg';
import Info from '@/shared/assets/svg/info-small.svg';
import { SalesPointsMap } from '@/widgets/sales-point-map';
import { CONSTANTS, Sections, initialDate, SECTIONS_NAMES, FormInitialValues } from './constants';
import { blackFridayDeadline } from '@/shared/constants/black-friday';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import { Point } from '@/shared/ui-kit/map/map.types';
import styles from './form.module.scss';

interface FormProps {
  isOpened: boolean;
  onClose: () => void;
  totalPrice: number;
  confirmPanel: ReactNode;
  header?: ReactNode;
  withHI?: boolean;
  handler?: (form: UseFormReturnType<FormInitialValues>) => void;
  requestIsLoading?: boolean;
  withoutSms?: boolean;
}

const Form: FC<FormProps> = memo(
  ({ withHI, totalPrice, isOpened, onClose, confirmPanel, header, handler, requestIsLoading, withoutSms }) => {
    const [openedSection, setOpenedSection] = useState<string>('');
    const [deliveryStatus, setDeliveryStatus] = useState<ItemStatus>();
    // const [paymentStatus, setPaymentStatus] = useState<ItemStatus>();
    const [infoStatus, setInfoStatus] = useState<ItemStatus>();
    const [confirmationStatus, setConfirmationStatus] = useState<ItemStatus>();
    const [smsStatus, setSmsStatus] = useState<ItemStatus>();
    const { isMobile } = useMediaQuery();
    const [date, setDate] = useState<Date>(initialDate());
    const inputRef = useRef<HTMLInputElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [opened, { open, close }] = useDisclosure(false);

    const isSmsLoading = useAppSelector((state) => state.app.isSmsLoading);
    const { selectedNumber, pinConfirmedNumber } = useAppSelector((state) => state.beautifulNumber);
    const inviteCode = Cookies.get('inviteCode');
    const whoRecommended = inviteCode ? decodeInviteCode(inviteCode) : '';
    const { data: aboutCompanyData } = useGetAboutCompanyQuery({
      populate: ['privacy_policy'],
    });
    const blackFridayCheckboxDeadline = new Date(blackFridayDeadline);
    const isPromotionExpired = isAfter(new Date(), blackFridayCheckboxDeadline);

    useEffect(() => {
      if (withoutSms) {
        setDisabled(false);
      }
    }, [withoutSms]);

    const form = useForm({
      initialValues: CONSTANTS.initialValues,

      validate: {
        delivery: (val) => (withHI ? null : val === '' ? '' : null),
        // payment: (val) => (withHI ? null : val === '' ? '' : null),
        info: {
          name: (val) => (val.length < 3 || /\d/.test(val) ? CONSTANTS.validationLabels.name : null),
          contact_phone: (val) =>
            !val
              ? CONSTANTS.validationLabels.phone
              : val.length === 10
                ? null
                : CONSTANTS.validationLabels.invalidPhone,
          email: (val) => {
            if (!val) {
              return null;
            } else {
              return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val) ? null : CONSTANTS.validationLabels.email;
            }
          },
          address: (val) => {
            if (
              ((form.values.delivery === 'true' || withHI) && val === '') ||
              (val.length < 3 && form.values.delivery === 'true')
            )
              return CONSTANTS.validationLabels.address;

            return null;
          },
          exactTime: () => null,
          date: () => null,
          point: (val) => {
            if (form.values.delivery === 'false' && val === '' && !withHI) return CONSTANTS.validationLabels.point;

            return null;
          },
          whoRecommended: () => null,
        },
        confirmation: (val) => (val ? null : ''),
        acceptPromotion: (val) => (val ? null : ''),
      },
    });

    useEffect(() => {
      const initial = withHI ? SECTIONS_NAMES[Sections.info] : SECTIONS_NAMES[Sections.delivery];
      setOpenedSection(initial);
    }, [withHI]);

    useEffect(() => {
      if (whoRecommended) {
        form.setFieldValue('info.whoRecommended', whoRecommended);
      }
    }, [whoRecommended]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.delivery]) {
        setDeliveryStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.isValid(SECTIONS_NAMES[Sections.delivery])
          ? setDeliveryStatus(ItemStatus.FILLED)
          : setDeliveryStatus(ItemStatus.DISABLED);
      }
    }, [form, form.values.delivery, openedSection]);

    const isPinCodeActivate = selectedNumber?.phone_id === pinConfirmedNumber;
    // useEffect(() => {
    //   if (openedSection === SECTIONS_NAMES[Sections.payment]) {
    //     setPaymentStatus(ItemStatus.IN_PROGRESS);
    //   } else {
    //     form.isValid(SECTIONS_NAMES[Sections.payment])
    //       ? setPaymentStatus(ItemStatus.FILLED)
    //       : setPaymentStatus(ItemStatus.DISABLED);
    //   }
    // }, [form, form.values.payment, openedSection]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.info]) {
        setInfoStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.isValid(SECTIONS_NAMES[Sections.info])
          ? setInfoStatus(ItemStatus.FILLED)
          : setInfoStatus(ItemStatus.DISABLED);
      }
    }, [form, form.values.info, openedSection]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.confirmation]) {
        setConfirmationStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.values.confirmation === true
          ? setConfirmationStatus(ItemStatus.FILLED)
          : setConfirmationStatus(ItemStatus.DISABLED);
      }
      if (openedSection === SECTIONS_NAMES[Sections.sms]) {
        setSmsStatus(ItemStatus.IN_PROGRESS);
      } else {
        setSmsStatus(ItemStatus.DISABLED);
      }
    }, [form.values.confirmation, openedSection]);

    useEffect(() => {
      if (form.values.info.exactTime === 'true') {
        open();
      }
    }, [form.values.info.exactTime, open]);

    useEffect(() => {
      if (form.values.delivery !== '') {
        if (form.values.delivery === 'true') {
          reachGoal(YM.click_cart_dostavka);
        } else if (form.values.delivery === 'false') {
          reachGoal(YM.click_cart_salon);
        }
        setOpenedSection(SECTIONS_NAMES[Sections.info]);
      }
      // form.values.delivery !== '' &&
      // && setOpenedSection(SECTIONS_NAMES[Sections.payment]);
      // form.values.payment !== ''
    }, [form.values.delivery /*, form.values.payment*/]);

    const onAccordionChange = (val: string) => {
      if (Sections[openedSection as keyof typeof Sections] > Sections[val as keyof typeof Sections]) {
        setOpenedSection(val);
      } else {
        let shouldOpen = true;
        for (const section in Sections) {
          if (
            Sections[val as keyof typeof Sections] > Sections[section as keyof typeof Sections] &&
            !form.isValid(section)
          ) {
            shouldOpen = false; //проверка валидности предыдущих пунктов
          }
        }
        shouldOpen ? setOpenedSection(val) : form.validateField(openedSection);
      }
    };

    const dataHandler = (d: Date) => {
      setDate(d);
      form.setFieldValue('info.date', d);
    };

    const next = () => {
      if (form.isValid(SECTIONS_NAMES[Sections.info])) {
        setOpenedSection(SECTIONS_NAMES[Sections.confirmation]);
        reachGoal(YM.click_cart_next_step2);
      } else {
        (!form.isValid('info.name') && form.validateField('info.name')) ||
          (!form.isValid('info.contact_phone') && form.validateField('info.contact_phone')) ||
          (!form.isValid('info.email') && form.validateField('info.email')) ||
          (!form.isValid('info.address') && form.validateField('info.address')) ||
          (!form.isValid('info.point') && form.validateField('info.point'));
      }
    };

    const [sendSms] = useSendSmsMutation();

    const onConfirm = () => {
      form.setFieldValue('confirmation', true);
      setOpenedSection(SECTIONS_NAMES[Sections.sms]);
      reachGoal(YM.click_cart_next_step3);
      const storageObj = storage.get('contact_phone');
      const parsedStorageObj = storageObj && JSON.parse(storageObj);
      const isNotSamePhone = parsedStorageObj && parsedStorageObj?.contact_phone === form.values.info.contact_phone;
      const timeIsExpired = parsedStorageObj && parsedStorageObj?.expirationTime > Date.now();

      if (!storageObj || isNotSamePhone || timeIsExpired) {
        sendSms({ contact_phone: form.values.info.contact_phone, source_id: ORDER_PARAMS.SOURCE_ID });
        const sessionObject = {
          contact_phone: form.values.info.contact_phone,
          expirationTime: Date.now() + 95000,
        };
        storage.set('contact_phone', JSON.stringify(sessionObject));
      }
    };

    const changeContactPhone = () => {
      setOpenedSection(SECTIONS_NAMES[Sections.info]);
      inputRef.current && inputRef.current.dispatchEvent(new Event('focus'));
      inputRef.current && inputRef.current.focus();
    };

    const SelectedPoint = ({ confirmation }: { confirmation?: boolean }) =>
      form.values.info.point !== '' ? (
        <SelectedItem
          icon={SELECTED_ICON.POINT}
          label={CONSTANTS.map.selectedLabel}
          value={form.values.info.point}
          confirmation={confirmation}
        />
      ) : null;

    const setAddress = (s: Point) => {
      const formattedTime = s?.time.replace('-', ' до ');

      return form.setFieldValue('info.point', `${s?.fullAddress} c ${formattedTime}`);
    };

    const footer = (
      <>
        {selectedNumber && !isPromotionExpired && (
          <div className={styles.confirm__checkbox}>
            <Checkbox
              className={styles.checkbox}
              checked={form.values.acceptPromotion}
              onClick={() => form.setFieldValue('acceptPromotion', !form.values.acceptPromotion)}
            />
            <span className={styles.promotionTitle}>
              Я принимаю{' '}
              <Link href={LINKS.BEAUTIFUL_NUMBERS_BLACK_FRIDAY_FILE} target='_blank' className={styles.link}>
                условия акции «Скидка на красивый номер»
              </Link>
            </span>
          </div>
        )}
        <div className={styles.confirm__footer}>
          <Button
            type='submit'
            onClick={() => handler && handler(form as UseFormReturnType<FormInitialValues>)}
            className={cn(styles.button, styles.button__submit)}
            classNames={{ icon: styles.button__icon }}
            disabled={
              !!disabled ||
              requestIsLoading ||
              isSmsLoading ||
              (selectedNumber ? !isPromotionExpired && !form.values.acceptPromotion : false)
            }
            leftIcon={!requestIsLoading && !isSmsLoading && <ShoppingCart />}
          >
            {requestIsLoading || isSmsLoading ? (
              <div className={styles.loading}>
                <LoadingOverlay visible loaderProps={{ color: 'white' }} />
              </div>
            ) : (
              <span>Оформить заказ {formatPrice(totalPrice)} </span>
            )}
          </Button>
          <span className={styles.confirm__confirmation}>
            Нажимая «Оформить заказ», вы принимаете{' '}
            {aboutCompanyData?.data?.attributes?.privacy_policy?.data?.map((item) => (
              <Link
                key={item.id}
                href={`/${apiEndpoints.files}/${item.attributes.hash}${item.attributes.ext}`}
                target='_blank'
                className={styles.confirm__link}
              >
                условия обработки персональных данных
              </Link>
            ))}
          </span>
        </div>
      </>
    );

    const accordionData = [
      {
        value: SECTIONS_NAMES[Sections.delivery],
        panel: (
          <RadioGroup
            {...form.getInputProps(SECTIONS_NAMES[Sections.delivery])}
            items={CONSTANTS.RADIOS.delivery.items}
          />
        ),
        control: (
          <Control
            field={SECTIONS_NAMES[Sections.delivery]}
            fieldValue={form.values.delivery}
            filled={deliveryStatus === ItemStatus.FILLED}
            disabled={deliveryStatus === ItemStatus.DISABLED}
          />
        ),
        status: deliveryStatus,
      },
      // {
      //   value: SECTIONS_NAMES[Sections.payment],
      //   panel: (
      //     <RadioGroup {...form.getInputProps(SECTIONS_NAMES[Sections.payment])} items={CONSTANTS.RADIOS.payment.items} />
      //   ),
      //   control: (
      //     <Control
      //       field={SECTIONS_NAMES[Sections.payment]}
      //       fieldValue={form.values.payment}
      //       filled={paymentStatus === ItemStatus.FILLED}
      //     />
      //   ),
      //   status: paymentStatus,
      // },
      {
        value: SECTIONS_NAMES[Sections.info],
        panel: (
          <div className={styles.info__container}>
            <div className={styles.info__description}>
              {withHI
                ? CONSTANTS.info.label.withHI
                : form.values.delivery === 'true'
                  ? CONSTANTS.info.label.delivery
                  : CONSTANTS.info.label.point}
            </div>
            <TextInput
              placeholder={CONSTANTS.info.name.placeholder}
              label={CONSTANTS.info.name.label}
              maxLength={50}
              withValidation
              {...form.getInputProps('info.name')}
              mask={/^[^\d]+$/}
            />
            <div className={styles.info__inputs}>
              <PhoneInput
                label={CONSTANTS.info.phoneLabel}
                inputRef={inputRef}
                {...form.getInputProps('info.contact_phone')}
              />
              <EmailInput
                maxLength={50}
                label={CONSTANTS.info.emailLabel}
                withoutValidation
                {...form.getInputProps('info.email')}
              />
            </div>
            {form.values.delivery === 'true' || withHI ? (
              <>
                <div className={styles.info__inputs}>
                  <div className={cn(styles.address, { [styles.addressWithHI]: withHI })}>
                    <TextInput
                      label={withHI ? CONSTANTS.info.address.hi_label : CONSTANTS.info.address.label}
                      placeholder={CONSTANTS.info.address.placeholder}
                      withValidation
                      maxLength={50}
                      {...form.getInputProps('info.address')}
                    />
                  </div>
                  {withHI && (
                    <div className={styles.recommended}>
                      <PhoneInput
                        labelStyles={styles.phoneLabelInput}
                        disabled={!!whoRecommended.length}
                        label={
                          <>
                            {CONSTANTS.info.whoRecommended}
                            <Tooltip
                              classNames={{
                                tooltip: cn(styles.tooltip),
                              }}
                              label={<RichText whiteText>{CONSTANTS.info.whoRecommendedTooltip}</RichText>}
                            >
                              <div className={styles.infoIcon}>
                                <Info />
                              </div>
                            </Tooltip>
                          </>
                        }
                        {...form.getInputProps('info.whoRecommended')}
                      />
                    </div>
                  )}
                </div>

                <div>
                  <div className={cn(styles.control__title, styles.control__title__time)}>
                    {CONSTANTS.RADIOS.exactTime.control}
                  </div>
                  <RadioGroup {...form.getInputProps('info.exactTime')} items={CONSTANTS.RADIOS.exactTime.items} />
                </div>
                {form.values.info.exactTime === 'true' && (
                  <div onClick={open}>
                    <TextInput
                      value={
                        form.values.info.date
                          ? format(form.values.info.date, 'dd.MM.y с HH:mm') +
                            format(addHours(form.values.info.date, 1), ' до HH:mm')
                          : ''
                      }
                      rightSection={<TimeIcon />}
                      style={{ pointerEvents: 'none' }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className={styles.map}>
                <div className={styles.control__title}>{CONSTANTS.map.label}</div>
                <SalesPointsMap isForm isError={form.getInputProps('info.point').error} onSelect={setAddress} />
                {form.getInputProps('info.point').error ? (
                  <span className={cn(styles.error, styles.error__map)}>{form.getInputProps('info.point').error}</span>
                ) : (
                  <SelectedPoint />
                )}
              </div>
            )}
            <div className={styles.info__footer}>
              <Button
                className={cn(styles.button, !form.isValid(SECTIONS_NAMES[Sections.info]) && styles.button__invalid)}
                onClick={next}
              >
                Далее
              </Button>
              <p>
                {withHI
                  ? CONSTANTS.info.label.withHIClue
                  : form.values.delivery === 'true'
                    ? CONSTANTS.info.label.deliveryClue
                    : CONSTANTS.info.label.pointClue}
              </p>
            </div>
          </div>
        ),
        control: (
          <Control
            field={SECTIONS_NAMES[Sections.info]}
            filled={infoStatus === ItemStatus.FILLED}
            disabled={infoStatus === ItemStatus.DISABLED}
          />
        ),
        status: infoStatus,
        internet: true,
      },
      {
        value: SECTIONS_NAMES[Sections.confirmation],
        control: (
          <Control
            field={SECTIONS_NAMES[Sections.confirmation]}
            filled={confirmationStatus === ItemStatus.FILLED}
            disabled={confirmationStatus === ItemStatus.DISABLED}
          />
        ),
        status: confirmationStatus,
        panel: (
          <>
            <div className={styles.confirm__wrapper}>{confirmPanel}</div>
            {form.values.delivery === 'true' ? (
              <SelectedItem
                icon={SELECTED_ICON.DELIVERY}
                label={CONSTANTS.confirmation.delivery}
                value={form.values.info.address}
                confirmation
              />
            ) : (
              <SelectedPoint confirmation />
            )}
            {!withHI && (
              <SelectedItem
                icon={SELECTED_ICON.PAYMENT}
                label={CONSTANTS.confirmation.online /*[form.values.payment as keyof typeof CONSTANTS.confirmation]*/}
                confirmation
              />
            )}
            {withHI && (
              <SelectedItem
                icon={SELECTED_ICON.CONNECTION}
                label={CONSTANTS.confirmation.connection}
                value={form.values.info.address}
                confirmation
              />
            )}
            {!withoutSms ? (
              <Button className={cn(styles.button, styles.confirm__button)} onClick={onConfirm}>
                Подтвердить
              </Button>
            ) : (
              footer
            )}
          </>
        ),
        internet: true,
      },
      {
        value: SECTIONS_NAMES[Sections.sms],
        control: CONSTANTS.sms.control,
        status: smsStatus,
        panel: (
          <SmsPanel
            contact_phone={form.values.info.contact_phone}
            footer={footer}
            opened={openedSection === SECTIONS_NAMES[Sections.sms]}
            disabled={disabled}
            changeContactPhone={changeContactPhone}
            setDisabled={setDisabled}
          />
        ),
        internet: true,
      },
    ];

    useEffect(() => {
      if (isOpened) {
        toggleWidgetVisibility(false);
      } else {
        toggleWidgetVisibility(true);
      }
    }, [isOpened]);

    return (
      <Modal
        opened={isOpened}
        onClose={onClose}
        centered
        classNames={{
          root: styles.modal__root,
          inner: styles.modal__inner,
          header: styles.modal__header,
          body: styles.modal__body,
          content: styles.modal__content,
        }}
      >
        <Box
          component='form'
          onSubmit={form.onSubmit(() => {
            // console.log(form.values, selectedTariffAndServices);
          })}
          className={cn(styles.container)}
        >
          <CloseIcon className={styles.close} onClick={onClose} />
          {header}
          <ScrollArea.Autosize
            w='calc(100% + 8px)'
            mah={isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 258px)'}
            type='auto'
            scrollbarSize='8px'
            offsetScrollbars
            classNames={{
              scrollbar: styles.scroll__bar,
              thumb: styles.scroll__thumb,
              viewport: styles.scroll__viewport,
            }}
          >
            <Accordion
              classNames={{
                control: styles.control,
                chevron: styles.control__chevron,
                label: styles.control__label,
                item: styles.item,
                content: styles.content,
              }}
              value={openedSection}
              onChange={(val) => val && onAccordionChange(val)}
              chevron={<ArrowDownIcon />}
              transitionDuration={500}
            >
              {accordionData
                .filter(({ internet }) => (withHI ? internet : true))
                .filter((item) => (withoutSms ? item.value !== SECTIONS_NAMES[Sections.sms] : true))
                .map((item, idx) => (
                  <AccordionItem {...item} key={idx} idx={idx} />
                ))}
            </Accordion>
          </ScrollArea.Autosize>
          {form.values.info.exactTime === 'true' && (
            <DateTimePicker date={date} handler={dataHandler} opened={opened} close={close} />
          )}
        </Box>
      </Modal>
    );
  },
);

Form.displayName = 'Form';
export { Form };
