import { FC, ReactNode } from 'react';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import { InfoWithTooltip } from '@/shared/ui-kit';
import { formatPrice } from '@/shared/lib/utils';
import { MOYA_VOLNA } from '@/modules/tariff/shared/constants';
import styles from './basket-footer.module.scss';

interface BasketFooterProps {
  price?: number;
  priceWithPromotion?: number;
  promotionPeriod?: string;
  promotionDescription?: ReactNode;
  isMainPage?: boolean;
  homeInternetAdded?: boolean;
}

export enum PRICE_CONATANTS {
  TITLE = 'К оплате',
  WITHOUT_PROMOTION = 'Без скидки',
  WITH_PROMOTION = 'Со скидкой',
}

export const BasketFooter: FC<BasketFooterProps> = ({
  price,
  priceWithPromotion,
  promotionPeriod,
  promotionDescription,
  isMainPage,
  homeInternetAdded
}) => {
  const pathname = usePathname();
  const isMoyaVolna = pathname.endsWith(MOYA_VOLNA.NAME);

  return priceWithPromotion ? (
    <div className={cn(styles.container, { [styles.container__main]: isMainPage })}>
      <div className={styles.title}>{PRICE_CONATANTS.TITLE}</div>
      <div className={cn(styles.row, styles.row__first)}>
        <span className={styles.row__title}>{PRICE_CONATANTS.WITHOUT_PROMOTION}</span>
        <span className={styles.price__old}>{formatPrice(price)}</span>
      </div>
      <div className={styles.row}>
        <span className={styles.row__title}>{PRICE_CONATANTS.WITH_PROMOTION}</span>
        <span className={styles.price__actual}>{formatPrice(priceWithPromotion)}</span>
      </div>
      {(priceWithPromotion && promotionPeriod && homeInternetAdded) ? 
        <InfoWithTooltip info={promotionPeriod} description={promotionDescription} /> : null
      }
      {isMoyaVolna && !homeInternetAdded ? <InfoWithTooltip info={MOYA_VOLNA.INFO} /> : null }
    </div>
  ) : (
    <div className={cn(styles.container, { [styles.container__main]: isMainPage })}>
      <div className={styles.row}>
        <span className={styles.title}>{PRICE_CONATANTS.TITLE}</span>
        <span className={styles.price__base}>{formatPrice(price)}</span>
      </div>
    </div>
  );
};
